import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.scss";
import Footer from "./components/Footer/Footer";
import Store from "./pages/Store/Store";
import Download from "./pages/Download/Download";
import CookieBanner from "./components/CookieBanner/CookieBanner";

const App = () => {
  return (
    <>
      <div className='app-container'>
        <div className='pages-container'>
          <Switch>
            <Route path='/' exact component={Store} />
            <Route path='/download' component={Download} />
            <Route path='/store' component={Store} />
          </Switch>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default App;
