import React, { useState, useEffect } from "react";
import { getStoreItems } from "../../contentful";
import "./StoreStyles.scss";
import Checkout from "./Checkout";
import StoreItem from "./StoreItem";
import Filters from "./Filters/Filters";
import MoreInfo from "./MoreInfo/MoreInfo";
import CookieBanner from "../../components/CookieBanner/CookieBanner";

const Store = () => {
  const [products, setProducts] = useState([]);
  const [displayProducts, setDisplayProducts] = useState([]);
  const [basket, setBasket] = useState([]);
  const [itemCodes, setItemCodes] = useState([]);
  const [showCheckout, setShowCheckout] = useState(false);
  const [moreInfoOverlay, setMoreInfoOverlay] = useState(null);
  const [cardsOnly, setCardsOnly] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (ready) {
    getStoreItems().then((data) => {
      const sorted = data.sort((a, b) => {
        let fa = a.fields.composer?.toLowerCase(),
          fb = b.fields.composer?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      setProducts(sorted);
      setDisplayProducts(sorted);
    });
    }
  }, [ready]);

  const addItem = (item) => {
    setBasket([...basket, item]);
    setItemCodes([...itemCodes, item.fields.productId]);
  };

  const removeItem = (item) => {
    setBasket(
      basket.filter((i) => i.fields.productId !== item.fields.productId)
    );
    setItemCodes(itemCodes.filter((code) => code !== item.fields.productId));
  };

  const acceptCookies = () => {
    setReady(true);
  };

  return (
    <div>
      <MoreInfo
        moreInfoOverlay={moreInfoOverlay}
        setMoreInfoOverlay={setMoreInfoOverlay}
        addItem={addItem}
        itemCodes={itemCodes}
      />

      <div>
        <Checkout
          setBasket={setBasket}
          setShowCheckout={setShowCheckout}
          itemCodes={itemCodes}
          basket={basket}
          showCheckout={showCheckout}
          setDisplayProducts={setDisplayProducts}
          displayProducts={displayProducts}
          products={products}
        />
        {!showCheckout && (
          <div className='store__filters'>
            <Filters
              displayProducts={displayProducts}
              products={products}
              setDisplayProducts={setDisplayProducts}
            />
          </div>
        )}
        <p className='store__disclaimer'>
          For printed editions and other enquiries contact
          <a href='mailto:bonesapartpublishing@gmail.com'>
            bonesapartpublishing@gmail.com
          </a>
        </p>
        <div className='store__items'>
          {displayProducts.length === 0 && ready && (
            <div className='store__no-results'>
              <p>Sorry, there are no results.</p>
              <p> Please try another search.</p>
            </div>
          )}
          {displayProducts &&
            displayProducts.map((item, index) => (
              <StoreItem
                key={index}
                item={item}
                index={index}
                addItem={addItem}
                removeItem={removeItem}
                itemCodes={itemCodes}
                setMoreInfoOverlay={setMoreInfoOverlay}
                moreInfoOverlay={moreInfoOverlay}
                cardsOnly={cardsOnly}
              />
            ))}
        </div>
      </div>
      {showCheckout && <div className='checkout__backdrop' />}
      {moreInfoOverlay && (
        <div onClick={() => setMoreInfoOverlay(null)} className='backdrop' />
      )}
      <CookieBanner acceptCookies={acceptCookies} />
    </div>
  );
};

export default Store;
