import React, { useState, useEffect } from "react";
import "./CookieBannerStyles.scss";
const CookieBanner = ({ acceptCookies }) => {
  const [open, setOpen] = useState(true);
  const [classes, setClasses] = useState("cookie-banner cookie-banner--active");

  useEffect(() => {
    setClasses(open ? "cookie-banner cookie-banner--active" : "cookie-banner");
  }, [open]);

  return (
    <>
      <div className={classes}>
        <p>
          When making a purchase from this website you will be required to
          submit personal information such as your email address and name. This
          is to confirm that your personal details will only be used for the
          purposes of carrying out the transaction.
        </p>

        <button
          className='cookie-banner__cta cookie-banner__cta--accept'
          onClick={() => {
            acceptCookies();
            setOpen(false);
          }}
        >
          Agree
        </button>
      </div>
      {open && <div className='backdrop' />}
    </>
  );
};

export default CookieBanner;
