import React, { useEffect } from "react";
import "./MoreInfoStyles.scss";
import Youtube from "../../../components/Youtube/Youtube";
import marked from "marked";
import Carousel from "../../../components/Carousel/Carousel";
import Sample from "../../../assets/sample.png";

const MoreInfo = ({
  moreInfoOverlay,
  setMoreInfoOverlay,
  addItem,
  itemCodes,
}) => {
  const {
    youtube,
    title,
    description,
    image,
    price,
    productId,
    facebook,
    samplePages,
    composer,
  } = moreInfoOverlay?.fields || {};

  useEffect(() => {
    window.addEventListener("keydown", handleKeypress);

    return () => window.removeEventListener("keydown", handleKeypress);
  }, []);

  const handleKeypress = (e) => {
    if (e.key === "Escape") setMoreInfoOverlay(null);
  };

  const classes = () =>
    moreInfoOverlay
      ? "more-info-overlay more-info-overlay--active"
      : "more-info-overlay";
  return (
    <div className={classes()}>
      <div className='more-info-overlay__content'>
        <button className='close-cta' onClick={() => setMoreInfoOverlay(null)}>
          X
        </button>
        <div className='more-info-overlay__content__layout'>
          {image && !samplePages && (
            <div className='more-info-overlay__content__score'>
              <img
                className='more-info-overlay__content__score--sample'
                src={Sample}
              />
              <img src={image.fields.file.url} alt={title} />
            </div>
          )}
          {samplePages && (
            <div className='more-info-overlay__content__score'>
              <Carousel items={[image, ...samplePages]} />{" "}
            </div>
          )}
          <div className='more-info-overlay__content__text'>
            <h3>{title}</h3>
            <h4 className='more-info-overlay__content__text__composer'>
              {composer}
            </h4>
            {description && (
              <div
                className='more-info-overlay__desc'
                dangerouslySetInnerHTML={{ __html: marked(description) }}
              />
            )}
            {(youtube || facebook) && (
              <div className='more-info-overlay__video'>
                <Youtube code={youtube} facebook={facebook} />
              </div>
            )}
            {price && <p>£{price}</p>}
            <button
              style={{ margin: "16px 0" }}
              onClick={() => addItem(moreInfoOverlay)}
              className={
                itemCodes.includes(productId)
                  ? "store__item__button--selected"
                  : "store__item__button"
              }
            >
              {itemCodes.includes(productId) ? "selected" : "add to cart"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfo;
