import React, { useEffect, useRef } from "react";
import Sample from "../../assets/sample.png";

const CarouselItem = ({ setIndex, index, item, snapToSlide }) => {
  const itemRef = useRef();

  const callback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setIndex(index);
      }
    });
  };

  const options = {
    rootMargin: "0px",
    threshold: 0.5,
  };

  const observer = new IntersectionObserver(callback, options);
  useEffect(() => {
    observer.observe(itemRef.current);
  }, [itemRef]);
  if (!item) return null;
  return (
    <div
      onClick={() => snapToSlide(index)}
      ref={itemRef}
      className={"carousel__item"}
    >
      <div className='carousel__image-wrapper'>
        <img className='carousel__item--sample' src={Sample} />

        <img src={item.fields.file.url} />
      </div>
    </div>
  );
};

export default CarouselItem;
