import { useState } from "react";
import axios from "axios";

const useSendEmail = () => {
  const [sent, setSent] = useState(false);

  const sendMail = async (html, subject, recipient) => {
    try {
      await axios.post(
        `https://us-central1-webspinner-1bc67.cloudfunctions.net/sendMail?html=${html}&subject=${subject}&to=${recipient}&account=BA&cc=bonesapartpublishing@gmail.com`,
        html
      );
      setSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  return { sendMail, sent };
};

export default useSendEmail;
