import React, { useRef, useEffect, useState } from "react";
import "./CarouselStyles.scss";
import CarouselItem from "./CarouselItem";
import Chevron from "../../assets/chevron.svg";

const Carousel = ({ items }) => {
  const carouselItems = useRef([]);
  const carouselRef = useRef();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const snapTo = (dir) => {
    if (carouselRef.current) {
      if (dir === "next" && selectedIndex < items.length - 1) {
        carouselRef.current.scrollTo({
          behavior: "smooth",
          left: carouselItems.current[selectedIndex + 1].offsetLeft,
        });
        setSelectedIndex(selectedIndex + 1);
      }
      if (dir === "prev" && selectedIndex > 0) {
        carouselRef.current.scrollTo({
          behavior: "smooth",
          left: carouselItems.current[selectedIndex - 1].offsetLeft,
        });
        setSelectedIndex(selectedIndex - 1);
      }
    }
  };

  const snapToSlide = (index) => {
    setSelectedIndex(index);
    carouselRef.current.scrollTo({
      behavior: "smooth",
      left: carouselItems.current[index].offsetLeft,
    });
  };

  return (
    <div className='carousel__wrapper'>
      <ul ref={carouselRef} className={"carousel"}>
        {items?.map((item, index) => (
          <li
            className={
              index !== selectedIndex ? "slide carousel__item--side" : "slide"
            }
            ref={(item) => {
              if (carouselItems.current && item) {
                return (carouselItems.current[index] = item);
              }
            }}
          >
            <CarouselItem
              item={item}
              setIndex={setSelectedIndex}
              index={index}
              snapToSlide={snapToSlide}
            />
          </li>
        ))}
      </ul>
      {items.length > 1 && (
        <div>
          <ul className='carousel__pagination'>
            {items?.map((i, index) => (
              <li key={index}>
                <button
                  className='carousel__pagination__button'
                  style={{
                    color: index === selectedIndex ? "black" : "grey",
                  }}
                  onClick={() => snapToSlide(index)}
                >
                  •
                </button>
              </li>
            ))}
          </ul>

          <div className='carousel__chevrons'>
            {selectedIndex > 0 && (
              <button
                className=' carousel__chevrons carousel__chevrons--prev'
                onClick={() => snapTo("prev")}
              >
                <img src={Chevron} />
              </button>
            )}

            {selectedIndex < items.length - 1 && (
              <button
                className='carousel__chevrons carousel__chevrons--next'
                onClick={() => snapTo("next")}
              >
                <img src={Chevron} />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
