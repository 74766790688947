const client = require("contentful").createClient({
  space: "2rqligg7a1l7",
  accessToken: "qjpQY__xpVN4XC1tjiE2hTe2H5AltUGn6e8KEEP0xr0",
});

const getStoreItems = () =>
  client
    .getEntries({ content_type: "product" })
    .then((response) => response.items);

export { getStoreItems };
