import React from "react";

const style = {
  // position: "absolute",
  bottom: 0,
  left: 0,
  right: -50,
  zIndex: 700,
  minHeight: "55px",
  color: "white",
  background: "#3b4069",
  display: "flex",
  // flexDirection: "column",
  alignItems: "center",
  padding: 10,
  justifyContent: "space-between",
};
const Footer = (props) => {
  return (
    <div style={style}>
      <small>
        Designed and built by Adam Woolf @{" "}
        <a style={{ color: "white" }} href='https://www.webspinner.eu'>
          WebSpinner.eu
        </a>
      </small>
      <small>
        Contact:{" "}
        <a
          style={{ color: "white" }}
          href='mailto:      bonesapartpublishing@gmail.com
'
        >
          bonesapartpublishing@gmail.com
        </a>
      </small>
    </div>
  );
};

export default Footer;
