import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./Filters.scss";

const Filters = ({ setDisplayProducts, displayProducts, products }) => {
  const history = useHistory();
  const location = useLocation();
  const [filters, setFilters] = useState([]);
  const [checkedRadio, setCheckedRadio] = useState("");
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const [composer, setComposer] = useState("");
  const [composerList, setComposerList] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const handleSize = () => {
    const width = window.innerWidth;
    setIsMobile(width <= 768);
  };

  useEffect(() => {
    handleSize();
    window.addEventListener("resize", handleSize);
    return () => window.removeEventListener("resize", handleSize);
  }, []);

  useEffect(() => {
    const qParams = new URLSearchParams(location.search);

    const textQ = qParams.get("text");
    const filtersQ = qParams.get("filters");
    const ensembleQ = qParams.get("ensemble");
    const composerQ = qParams.get("composer");

    if (textQ) {
      setSearch(textQ);
      setDisplayProducts([
        ...products.filter(
          (p) =>
            p.fields.title.toLowerCase().includes(textQ) ||
            p.fields?.composer?.toLowerCase().includes(textQ) ||
            p.fields?.description?.toLowerCase().includes(textQ)
        ),
      ]);
    }

    if (filtersQ) {
      setFilters(filtersQ.split("_").filter(Boolean));
    }
    if (ensembleQ) {
      setCheckedRadio(ensembleQ);
    }
    if (composerQ) {
      setComposer(composerQ);
    }
  }, [products, location]);

  const handleResize = () => {
    setShow(window.innerWidth > 768);
  };

  useEffect(() => {
    setShow(window.innerWidth > 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (filters.length > 0 || checkedRadio) {
      setSearch("");
    }
    if (filters.length === 0 && !checkedRadio && !search && !composer) {
      setDisplayProducts(products);
    }

    if (checkedRadio) {
      setDisplayProducts(products.filter((p) => p.fields[checkedRadio]));
    }
    if (filters.length > 0 && !checkedRadio) {
      const array = [];
      products.map((p) => {
        let show = true;
        filters.forEach((f) => {
          if (!Object.keys(p?.fields).join(" ").includes(f)) show = false;
        });
        if (show) array.push(p);
      });

      setDisplayProducts(array);
    }

    if (filters.length > 0 && checkedRadio) {
      const array = [];
      products.map((p) => {
        let show = true;
        filters.forEach((f) => {
          if (!Object.keys(p?.fields).join(" ").includes(f)) show = false;
        });
        if (show) array.push(p);
      });

      setDisplayProducts(array.filter((p) => p.fields[checkedRadio]));
    }
  }, [products, filters, checkedRadio, search]);

  useEffect(() => {
    if (products) {
      const compArray = products.reduce((acc, c) => {
        acc.push(c.fields.composer.trim());
        if (c.fields.includedComposers) {
          const cArray = c.fields.includedComposers.split("|");
          cArray.forEach((c) => acc.push(c.trim()));
        }
        return acc;
      }, []);
      setComposerList(
        [...new Set(compArray)].sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        })
      );
    }
  }, [products]);

  useEffect(() => {
    if (composer) {
      setFilters([]);
      setSearch("");
      setCheckedRadio("");
      setDisplayProducts([
        ...products.filter(
          (p) =>
            p.fields.composer.toLowerCase().trim() === composer.toLowerCase() ||
            p.fields?.includedComposers?.includes(composer)
        ),
      ]);
    }
  }, [composer, products]);

  const handleTextInput = (e) => {
    const string = e.target.value.toLowerCase();
    setSearch(string);
    console.log(string);
    if (filters.length || checkedRadio || composer) {
      setFilters([]);
      setCheckedRadio("");
      setComposer("");
    }
    history.push(`?text=${e.target.value.toLowerCase()}`);
    if (e.target.value.length === 0) history.replace("");

    setDisplayProducts([
      ...products.filter(
        (p) =>
          p.fields.title.toLowerCase().includes(string) ||
          p.fields?.composer?.toLowerCase().includes(string) ||
          p.fields?.description?.toLowerCase().includes(string) ||
          p.fields?.includedComposers?.toLowerCase().includes(string)
      ),
    ]);
  };

  const handleRadio = (e) => {
    setCheckedRadio(e.target.value);
    setComposer("");
    const currentF = filters.join("_");
    if (currentF) {
      history.push(`?ensemble=${e.target.value}&filters=${currentF}`);
    } else {
      history.push(`?ensemble=${e.target.value}`);
    }
  };

  const updateQueryString = (value) => {
    const currentF = filters.join("_");
    if (currentF.includes(value)) {
      const newF = filters.filter((f) => f !== value).join("_");

      if (checkedRadio) {
        if (newF) {
          history.push(`?ensemble=${checkedRadio}&filters=${newF}`);
        } else {
          history.push(`?ensemble=${checkedRadio}`);
        }
      } else {
        history.push(`?filters=${newF}`);
      }
    } else {
      if (checkedRadio) {
        history.push(`?ensemble=${checkedRadio}&filters=${currentF}_${value}`);
      } else {
        history.push(`?filters=${currentF}_${value}`);
      }
    }
  };

  const handleFilter = (e) => {
    const value = e.target.value;
    updateQueryString(value);
    setComposer("");

    if (!filters.includes(value)) {
      setFilters([...filters, value]);
    } else {
      setFilters([...filters.filter((f) => f !== value)]);
    }
  };

  const showAll = () => {
    setDisplayProducts(products);
    setCheckedRadio("");
    setFilters([]);
    setSearch("");
    setComposer("");
    history.replace("");
  };

  const handleComposerSelect = (e) => {
    if (!e.target.value) {
      showAll();
    } else {
      history.push(`?composer=${e.target.value}`);
    }
  };

  const filterList = [
    {
      label: "3 trombones",
      value: "tromboneTrio",
      type: "radio",
      name: "ensemble",
      onChange: handleRadio,
    },
    {
      label: "4 trombones",
      value: "tromboneQuartet",
      type: "radio",
      name: "ensemble",
      onChange: handleRadio,
    },
    {
      label: "3 trombones & tuba",
      value: "threeTrombonesAndTuba",
      type: "radio",
      name: "ensemble",
      onChange: handleRadio,
    },
    {
      label: "5 to 12 trombones",
      value: "trombones5To12",
      type: "radio",
      name: "ensemble",
      onChange: handleRadio,
    },
    {
      label: "beginner",
      value: "beginner",
      type: "checkbox",
      name: "level",
      onChange: handleFilter,
    },
    {
      label: "junior",
      value: "junior",
      type: "checkbox",
      onChange: handleFilter,
    },
    {
      label: "intermediate",
      value: "intermediate",
      type: "checkbox",
      name: "level",
      onChange: handleFilter,
    },
    {
      label: "advanced",
      value: "advanced",
      type: "checkbox",
      name: "level",
      onChange: handleFilter,
    },

    {
      label: "seasonal music",
      value: "seasonalMusic",
      type: "checkbox",
      onChange: handleFilter,
    },
  ];

  return (
    <div className='filters'>
      <button className='filters__toggle' onClick={() => setShow(!show)}>
        {show ? "hide" : "show"} filters
      </button>

      <div className='filters__inputs'>
        <div>
          <div className='filters__input-container'>
            <input
              type='search'
              onChange={handleTextInput}
              placeholder='Type to search'
              className='filters__search'
              value={search}
            />
          </div>
          <div className='filters__input-container'>
            <select
              value={composer}
              className='filters__search'
              onChange={handleComposerSelect}
            >
              <option value=''>Select a composer</option>
              {composerList.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </select>
          </div>
        </div>
        <p className='filters__results'>
          {displayProducts?.length} of {products?.length}
        </p>
      </div>

      {show && (
        <div className='filters__container'>
          <div className='filters__list'>
            {filterList.map((f) => {
              if (products.some((p) => p.fields[f.value]))
                return (
                  <label key={f.value} className='filters__filter'>
                    {f.label}
                    <input
                      value={f.value}
                      type={f.type}
                      name={f?.name}
                      onChange={f.onChange}
                      checked={
                        f.type === "radio"
                          ? checkedRadio === f.value
                          : filters.includes(f.value)
                      }
                    />
                  </label>
                );
            })}
            <button
              onClick={() => {
                showAll();
                isMobile && setShow(!show);
              }}
              className='filters__filter__button'
            >
              clear filters
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filters;
