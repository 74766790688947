import React, { useState, useEffect } from "react";

const Youtube = ({ code, facebook }) => {
  const [url, setURL] = useState("");

  useEffect(() => {
    if (code) {
      if (code.includes("youtu.be")) {
        const param = code.split(".be/")[1].split("?")[0];
        const u = `www.youtube.com/embed/${param}`;
        setURL(u);
      } else {
        const param = code ? code.split("=")[1] : "";
        const u = `www.youtube.com/embed/${param}`;
        setURL(u);
      }
    }
    if (facebook) {
      const https = facebook.split("https://")[1];
      const string = https.split("'")[0];
      setURL(string);
    }
  }, [facebook, code]);

  return (
    <iframe
      className='youtube'
      src={`https://${url}`}
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
    />
  );
};

export default Youtube;
