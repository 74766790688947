import React, { useState } from "react";
import marked from "marked";
import demoAudio from "../../assets/Valentine.m4a";
import Youtube from "../../components/Youtube/Youtube";
import movie from "../../assets/movie.png";

const StoreItem = ({
  item,
  index,
  itemCodes,
  removeItem,
  addItem,
  setMoreInfoOverlay,
  moreInfoOverlay,
  cardsOnly,
}) => {
  const [moreInfo, setMoreInfo] = useState(null);
  const {
    price,
    image,
    isDownload,
    description,
    externalLink,
    productId,
    title,
    composer,
    youtube,
  } = item.fields;

  const toggleMoreInfo = () => {
    if (!cardsOnly) {
      setMoreInfoOverlay(moreInfoOverlay ? null : item);
      return;
    }

    if (!moreInfo) {
      setMoreInfo({
        title,
        description,
        composer,
      });
    } else {
      setMoreInfo(null);
    }
  };
  return (
    <div
      className='store__item'
      style={{ animationDelay: `${index * 0.2}s` }}
      key={index}
    >
      <div className='store__item__top'>
        {isDownload && <span className='store__item__download-badge' />}
        {!cardsOnly && <img src={image.fields.file.url} alt={title} />}
        {moreInfo?.title && (
          <div className='store__item__more-info__panel'>
            <div className='store__item__text'>
              <h3>{moreInfo.title}</h3>
              <p>{moreInfo?.composer}</p>

              {description && (
                <p
                  className='store__item__text__desc'
                  dangerouslySetInnerHTML={{
                    __html: marked(moreInfo.description),
                  }}
                />
              )}
            </div>
            {/* <audio controls>
              <source src={demoAudio} />
            </audio> */}
            {youtube && (
              <div className='store__item__video'>
                <Youtube code={youtube} />
              </div>
            )}
          </div>
        )}
      </div>

      <div className='store__item__text'>
        {!moreInfo?.title && (
          <>
            {" "}
            <h3>{title}</h3>
            <p>{composer}</p>
          </>
        )}
      </div>
      <button onClick={toggleMoreInfo} className='store__item__more-info__cta'>
        {!moreInfo?.title ? "more info" : "hide more info"}
        {youtube && !moreInfo?.title && (
          <div className='store__item__more-info__cta__icon'>
            <img src={movie} alt='movie_logo' />
          </div>
        )}
      </button>
      <div className='store__item__text__buttons'>
        {item.fields.price && <p className='store__item__price'>£{price}</p>}
        <>
          <button
            onClick={() => addItem(item)}
            className={
              itemCodes.includes(productId)
                ? "store__item__button--selected"
                : "store__item__button"
            }
          >
            {itemCodes.includes(productId) ? "selected" : "add to cart"}
          </button>
          {itemCodes.includes(productId) && (
            <button
              onClick={() => removeItem(item)}
              className='store__item__button--remove'
            >
              remove
            </button>
          )}
        </>
      </div>
    </div>
  );
};

export default StoreItem;
